export default () => {
  
	const { authToken, emulatedUserId, logout } = useAuth()
  const config = useRuntimeConfig()

  function _request(url, options) {
		const headers = options?.headers ?? {}

		if (authToken.value) {
			headers['Authorization'] = `Bearer ${authToken.value}`
		}

		if (emulatedUserId.value) {
			headers['isEmulation'] = emulatedUserId.value
		}

		if (options?.debug) {
			headers['x-query-debug'] = true
		}

		if (options?.filters) {
			headers['x-filters'] = JSON.stringify(options.filters)
		}

		if (options?.groupBy) {
			headers['x-group-by'] = options.groupBy
		}

		if (options?.pagination)  {
			headers['x-pagination'] = JSON.stringify(options.pagination)
		}

    return $fetch(`${config.public.baseURL}/${url}`, {
      ...options,
      headers,
      onResponse(ctx) {
        return ctx.response._data = ctx.response._data.data
      },
      onResponseError({ response	 }) {
        const route = useRoute()
  
        if (response.status === 401) { // Unauthorized
          const whiteList = ['/login', '/create-pass', '/forgot-pass', '/confirm-forgot-pass', /^\/siteresponse\/.*/]
          logout()
          if (!whiteList.find(path => {
            if (path === route.path) return true
            if (path.test && path.test(route.path)) return true
            return false
          })) {
            navigateTo('/login')
          }
        }
      }
    })    

  }

  async function getList (entity, filters = {}, pagination = {}, groupby = undefined, nested=1) {
    return _request(entity, {
      method: 'get',
      headers: {
        'x-filters': JSON.stringify(filters),
        'x-pagination': JSON.stringify(pagination),
        ...( nested && {'x-foreign-levels': nested}),
        ...( groupby && {'x-group-by': groupby})
      },
      transform: ret => { 
        return Array(ret.data)
      }
    })
  }
  
  function getCount (entity, filters = {}) {
    return useAPI(entity, {
      method: 'get',
      headers: {
        'x-filters': JSON.stringify(filters),
        'x-pagination': JSON.stringify({ rowsPerPage: 1 })
      }
    }).then(ret => ret.tot)
  }
  
  
  
  function getDetail (entity, item) {
    return useAPI(entity + (item && item.id ? '/' + item.id  : ''))
  }
  
  function get (url) {
    return useAPI({
      url,
      method: 'get',
    })
  }
  
  function put (entity, id, data) {
    const method = 'put'
    const url = '/' + entity + '/' + id
    return useAPI(url, {
      method,
      data
    })
  }
  
  function upsert (entity, data, method = 'POST', primary_key = data.id) {
    let url = entity

    // has to check for the full primary key
    if (method === 'PUT') {
      url = entity + '/' + primary_key
    }
    return useAPI(url, {
      method,
      body: data
    })
  }
  
  function remove (entity, item) {
    return useAPI(entity + '/' + item.id, {
      method: 'delete'
    })
  }
  
  return {
    getList,
    getCount,
    upsert,
    remove,
    get,
    getDetail,
    put
  }
}